import Player from '@vimeo/player';
import { events, emitEvent } from '../utilities/custom-events';
import { isIos, isMobile } from '../utilities/devices';

const AR = 16 / 9;

export default class {
    constructor({
        id,
        modalVideoHandle,
        leftClass = 'has-left',
        readyClass = 'is-ready',
    }) {
        const el = document.getElementById(id);
        const cont = el.parentElement;
        const backgroundVideo = el.querySelector('iframe');
        const backgroundPlayer = new Player(backgroundVideo);
        const playButton = el.querySelector('button');
        const modalVideo = el.querySelector(modalVideoHandle);
        const onMobile = isMobile() || isIos();

        let modalPlayer = null;

        function setVideoSize() {
            if (cont.offsetWidth / cont.offsetHeight > 16 / 9) {
                backgroundVideo.style.width = `${cont.offsetWidth}px`;
                backgroundVideo.style.height = `${cont.offsetWidth / AR}px`;
            } else {
                backgroundVideo.style.width = `${cont.offsetHeight * AR}px`;
                backgroundVideo.style.height = `${cont.offsetHeight}px`;
            }
        }

        if (!onMobile) {
            el.onmouseenter = () => { el.classList.remove(leftClass); };
            el.onmouseleave = () => { el.classList.add(leftClass); };
            el.onmousemove = ({ clientX, clientY }) => {
                const { offsetTop, offsetLeft } = el;
                const { top, left } = el.getBoundingClientRect();

                playButton.style.top = `${clientY - top - offsetTop}px`;
                playButton.style.left = `${clientX - left - offsetLeft}px`;
            };
        }
        window.addEventListener(events.closeView, e => {
            if (e.detail.view !== 'modal') return;

            backgroundPlayer.play();
            if (modalPlayer) modalPlayer.pause();
        });
        window.addEventListener('resize', setVideoSize);
        playButton.onclick = () => {
            emitEvent(events.loadView, {
                view: 'modal',
                markup: modalVideo.outerHTML,
                cb: modalContent => {
                    modalPlayer = new Player(modalContent.querySelector('iframe'));
                    modalPlayer.play();
                },
            });
        };

        setVideoSize();
        backgroundPlayer.on('playing', () => { el.classList.add(readyClass); });
        backgroundPlayer.on('error', () => { el.classList.remove(readyClass); });
        backgroundPlayer.play();
    }
}
