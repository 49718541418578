// Config
import { siteHandle } from '../config';

// Utilites functions
import { spyImages } from './spy';
import { newlines, superscript, wrapFreeText, wrapBrokenText } from './text';

const ENTER_CLASS = 'enter';
const EXIT_CLASS = 'exit';

let _classMap = null; // eslint-disable-line no-underscore-dangle

/**
 * Instantiate any components pushed onto global components array
 * @param {node} container
 * @param {object} map
 * @return {void}
 */
export function instantiate(container = document.body, map = null) {
    if (map !== null) {
        _classMap = map;
    }

    if (_classMap === null) return;

    while (window[siteHandle].components.length > 0) {
        const { components, state } = window[siteHandle];
        const config = components.shift();
        const Class = _classMap[config.handle];

        if (typeof Class === 'function') {
            new Class({ ...config, state }); // eslint-disable-line no-new
        }
    }
    // Need a setTimeout on animation enter class assignment
    // to ensure markup is in place for transitions
    setTimeout(() => {
        container.classList.remove(EXIT_CLASS);
        container.classList.add(ENTER_CLASS);
    }, 100);

    spyImages();
    newlines();
    superscript();
    wrapFreeText();
    wrapBrokenText();
}

/**
 * Inject new markup into DOM after adding configs to global
 * components array then instantiate newly added components
 * @param {node} container
 * @param {string} markup
 * @param {bool} append
 * @return {void}
 */
/* eslint-disable no-eval */
export function injectMarkup(container = null, markup = '', append = false) {
    if (!container) return;

    // Add markup to container
    if (append) {
        container.insertAdjacentHTML('beforeend', markup);
    } else {
        container.innerHTML = markup;
    }

    // Push components configs to global object
    const scripts = container.querySelectorAll('script');

    // Evaluate scripts returned from component markup if for new component
    Array.from(scripts).forEach(script => {
        if (script.textContent.includes(`.${siteHandle}.`)) {
            eval(script.textContent);
        }
    });

    // Instantiate components
    instantiate(container);
}
/* eslint-enable no-eval */

/**
 * clear markup before routing
 * @param {node} container
 * @return {void}
 */
export function clearMarkup(container = null) {
    if (!container) return;

    container.classList.remove(ENTER_CLASS);
    container.classList.add(EXIT_CLASS);
}
